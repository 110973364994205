var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[(_vm.loading_info)?_c('v-col'):_c('v-col',{staticClass:"col-12"},[_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"col-12 col-md-6 d-flex"},[_c('v-spacer'),(
              ['charging', 'preparing', 'reserved'].includes(
                _vm.charging.status
              ) &&
              !_vm.$vuetify.breakpoint.mobile &&
              _vm.$auth.check('superadmin')
            )?_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.stop_charging}},[_vm._v(" Завершить зарядку ")]):_vm._e(),_c('v-spacer'),(
              ['waiting_payment'].includes(_vm.charging.status) &&
              !_vm.$vuetify.breakpoint.mobile &&
              _vm.$auth.check('superadmin')
            )?_c('v-btn',{attrs:{"color":"red"},on:{"click":_vm.payment_charge}},[_vm._v(" Провести оплату ")]):_vm._e(),(!_vm.$vuetify.breakpoint.mobile)?_c('v-btn',{on:{"click":function($event){return _vm.point_info(_vm.charging.charge_point.point_number)}}},[_vm._v("Станция "+_vm._s(_vm.charging.charge_point.point_number)+" "+_vm._s(_vm.charging.charge_point.address))]):_vm._e()],1)],1),(_vm.$vuetify.breakpoint.mobile)?_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.mobile},on:{"click":function($event){return _vm.point_info(_vm.charging.charge_point.point_number)}}},[_vm._v("Станция "+_vm._s(_vm.charging.charge_point.point_number)+" ")])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('v-row',[_c('v-col',[(
              ['charging', 'preparing', 'reserved'].includes(
                _vm.charging.status
              ) && _vm.$auth.check('superadmin')
            )?_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.mobile,"color":"red"},on:{"click":_vm.stop_charging}},[_vm._v(" Завершить зарядку ")]):_vm._e()],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.mobile)?_c('v-row',[_c('v-col',[(
              ['waiting_payment'].includes(_vm.charging.status) &&
              _vm.$auth.check('superadmin')
            )?_c('v-btn',{attrs:{"block":_vm.$vuetify.breakpoint.mobile,"color":"red"},on:{"click":_vm.payment_charge}},[_vm._v(" Провести оплату ")]):_vm._e()],1)],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"col-12 col-md-4 ma-auto"},[_c('h3',{staticClass:"d-inline-flex"},[_vm._v("Транзакция:")]),_c('span',[_vm._v(_vm._s(_vm.charging.transaction_id))])]),_c('v-col',{staticClass:"col-12 col-md-4 ma-auto"},[_c('h3',{staticClass:"d-inline-flex"},[_vm._v("Статус ")]),_c('v-chip',{attrs:{"color":_vm.status_color(_vm.charging.status),"text-color":"white"}},[_vm._v(_vm._s(_vm.charging_status(_vm.charging.status)))])],1),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Клиент ")]),_c('v-btn',{staticClass:"ma-0",attrs:{"text":"","to":{
              name: 'client_info',
              params: { client_id: _vm.charging.client.id },
            }}},[_vm._v(" "+_vm._s(_vm.client_text)+" "),_c('v-icon',{attrs:{"right":"","dark":""}},[_vm._v(" mdi-launch ")])],1)],1),_c('v-col',{staticClass:"col-12 col-md-4 ma-auto"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Продолжительность")]),_vm._v(" "+_vm._s(_vm.duration())+" ")]),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('div',{staticClass:"d-inline-flex fill-height"},[_c('h4',{staticClass:"ma-auto"},[_vm._v("Сумма ")]),_c('div',{staticClass:"ma-auto"},[_vm._v(_vm._s(_vm.charging.payment_amount)+" руб")])])]),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('div',{staticClass:"d-inline-flex"},[_c('h4',{staticClass:"ma-auto"},[_vm._v("Коннектор ")]),_c('div',{staticClass:"icon"},[_c('div',{staticClass:"sprite outlet"},[_c('div',{class:_vm.connector_img(_vm.charging.reserved_connector.connector_type)})])]),_c('h4',{staticClass:"px-2 d-inline-block mt-auto mb-auto"},[_vm._v(" "+_vm._s(String(_vm.charging.reserved_connector.connector_type) .charAt(0) .toUpperCase() + String(_vm.charging.reserved_connector.connector_type).slice(1))+" ")])])]),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('div',{staticClass:"d-inline-flex fill-height"},[_c('h4',{staticClass:"ma-auto"},[_vm._v(_vm._s(_vm.reserve))])])]),_c('v-col',{staticClass:"col-12 col-md-4"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Передано энергии")]),_vm._v(" "+_vm._s(_vm.charging.power_amount)+" кВт")])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_vm._v("История")]),_c('v-data-table',{attrs:{"headers":_vm.history_headers,"items":_vm.history,"options":_vm.options,"server-items-length":_vm.count,"loading":_vm.loading_history,"item-key":"id","loading-text":"Загрузка... Подождите","no-data-text":"Ничего не найдено","items-per-page":25,"footer-props":{
              'items-per-page-options': [10, 25, 50, 100, -1],
              showFirstLastPage: true,
              'items-per-page-text': 'Всего на странице',
            }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.status",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.charging_status(item.status))+" ")]}},{key:"item.raw.charge_power",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convert_power(item.raw.charge_power))+" ")]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }