<template>
  <v-container fluid>
    <v-row>
      <v-col v-if="loading_info"></v-col>
      <v-col class="col-12" v-else>
        <v-row>
          <!--          <v-col class="col-6 col-md-4">-->
          <!--            <v-btn @click="go_back">-->
          <!--              <v-icon>mdi-arrow-left</v-icon>Назад</v-btn-->
          <!--            >-->
          <!--          </v-col>-->
          <v-spacer></v-spacer>
          <v-col class="col-12 col-md-6 d-flex">
            <v-spacer />
            <v-btn
              v-if="
                ['charging', 'preparing', 'reserved'].includes(
                  charging.status
                ) &&
                !$vuetify.breakpoint.mobile &&
                $auth.check('superadmin')
              "
              color="red"
              @click="stop_charging"
            >
              Завершить зарядку
            </v-btn>
            <v-spacer />
            <v-btn
              v-if="
                ['waiting_payment'].includes(charging.status) &&
                !$vuetify.breakpoint.mobile &&
                $auth.check('superadmin')
              "
              color="red"
              @click="payment_charge"
            >
              Провести оплату
            </v-btn>
            <v-btn
              v-if="!$vuetify.breakpoint.mobile"
              @click="point_info(charging.charge_point.point_number)"
              >Станция {{ charging.charge_point.point_number }}
              {{ charging.charge_point.address }}</v-btn
            >
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.mobile">
          <v-col>
            <v-btn
              :block="$vuetify.breakpoint.mobile"
              @click="point_info(charging.charge_point.point_number)"
              >Станция {{ charging.charge_point.point_number }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.mobile">
          <v-col>
            <v-btn
              v-if="
                ['charging', 'preparing', 'reserved'].includes(
                  charging.status
                ) && $auth.check('superadmin')
              "
              :block="$vuetify.breakpoint.mobile"
              color="red"
              @click="stop_charging"
            >
              Завершить зарядку
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.mobile">
          <v-col>
            <v-btn
              v-if="
                ['waiting_payment'].includes(charging.status) &&
                $auth.check('superadmin')
              "
              :block="$vuetify.breakpoint.mobile"
              color="red"
              @click="payment_charge"
            >
              Провести оплату
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="col-12 col-md-4 ma-auto">
            <h3 class="d-inline-flex">Транзакция:</h3>
            <span>{{ charging.transaction_id }}</span>
          </v-col>
          <v-col class="col-12 col-md-4 ma-auto"
            ><h3 class="d-inline-flex">Статус&nbsp;</h3>
            <v-chip :color="status_color(charging.status)" text-color="white">{{
              charging_status(charging.status)
            }}</v-chip></v-col
          >
          <v-col class="col-12 col-md-4"
            ><span class="font-weight-bold">Клиент&nbsp;</span>
            <v-btn
              text
              class="ma-0"
              :to="{
                name: 'client_info',
                params: { client_id: charging.client.id },
              }"
            >
              {{ client_text }}
              <v-icon right dark> mdi-launch </v-icon>
            </v-btn>
          </v-col>
          <v-col class="col-12 col-md-4 ma-auto"
            ><span class="font-weight-bold">Продолжительность</span>
            {{ duration() }}
          </v-col>
          <v-col class="col-12 col-md-4">
            <div class="d-inline-flex fill-height">
              <h4 class="ma-auto">Сумма&nbsp;</h4>

              <div class="ma-auto">{{ charging.payment_amount }} руб</div>
            </div>
          </v-col>
          <v-col class="col-12 col-md-4">
            <div class="d-inline-flex">
              <h4 class="ma-auto">Коннектор&nbsp;</h4>
              <div class="icon">
                <div class="sprite outlet">
                  <div
                    v-bind:class="
                      connector_img(charging.reserved_connector.connector_type)
                    "
                  ></div>
                </div>
              </div>
              <h4 class="px-2 d-inline-block mt-auto mb-auto">
                {{
                  String(charging.reserved_connector.connector_type)
                    .charAt(0)
                    .toUpperCase() +
                  String(charging.reserved_connector.connector_type).slice(1)
                }}
              </h4>
            </div>
          </v-col>
          <v-col class="col-12 col-md-4">
            <div class="d-inline-flex fill-height">
              <h4 class="ma-auto">{{ reserve }}</h4>
            </div>
          </v-col>
          <v-col class="col-12 col-md-4"
            ><span class="font-weight-bold">Передано энергии</span>
            {{ charging.power_amount }} кВт</v-col
          >
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4>История</h4>
            <v-data-table
              :headers="history_headers"
              :items="history"
              :options.sync="options"
              :server-items-length="count"
              :loading="loading_history"
              item-key="id"
              loading-text="Загрузка... Подождите"
              no-data-text="Ничего не найдено"
              :items-per-page="25"
              :footer-props="{
                'items-per-page-options': [10, 25, 50, 100, -1],
                showFirstLastPage: true,
                'items-per-page-text': 'Всего на странице',
              }"
            >
              <template v-slot:item.status="{ item }">
                {{ charging_status(item.status) }}
              </template>
              <template v-slot:item.raw.charge_power="{ item }">
                {{ convert_power(item.raw.charge_power) }}
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "ChargingInfo",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  data: () => {
    return {
      prevRoute: null,
      options: {
        sortBy: ["id"],
        sortDesc: [true],
        page: 1,
        itemsPerPage: 25,
      },
      history_headers: [
        {
          text: "Время",
          align: "start",
          sortable: true,
          value: "created_at",
        },
        {
          text: "Статус",
          align: "start",
          sortable: true,
          value: "status",
          width: "15%",
        },
        {
          text: "Передано энергии, kWt",
          align: "start",
          sortable: true,
          value: "raw.power_amount",
        },
        {
          text: "Заряд батареи, %",
          align: "start",
          sortable: true,
          value: "raw.battery_level",
        },
        {
          text: "Мощность заряда, kWt",
          align: "start",
          sortable: true,
          value: "raw.charge_power",
        },
      ],
    };
  },
  beforeDestroy() {
    this.$store.dispatch("clear_charging_info");
  },
  created() {
    this.$store.dispatch("load_charging_info", this.$route.params.charge_id);
  },
  watch: {
    options: {
      handler() {
        this.load_charging_history();
      },
      deep: true,
    },
  },
  computed: {
    count() {
      return this.$store.getters.HISTORY_COUNT;
    },
    loading_info() {
      return this.$store.getters.LOADING_CHARGE_INFO;
    },
    loading_history() {
      return this.$store.getters.HISTORY_LOADING;
    },
    charging() {
      return this.$store.getters.CHARGING_INFO;
    },
    history() {
      return this.$store.getters.CHARGING_HISTORY;
    },
    reserve() {
      if (this.charging.reserve_time != null) {
        return "Бронирование до " + this.charging.reserve_time;
      }
      return "Без бронирования";
    },
    client_text() {
      if (this.charging.client.phone != null) {
        return this.charging.client.phone;
      }
      return this.charging.client.surname + " " + this.charging.client.name;
    },
  },
  methods: {
    load_charging_history() {
      this.$store.dispatch("load_charging_history", {
        charging_id: this.$route.params.charge_id,
        options: this.options,
      });
    },
    duration() {
      let end_time = this.$moment(this.charging.updated_at);

      if (this.charging.status == "reserved") {
        end_time = this.$moment(new Date());
      }

      const start_time = this.$moment(this.charging.created_at);
      return this.$moment.utc(end_time.diff(start_time)).format("HH:mm:ss");
    },
    charging_status(status) {
      switch (status) {
        case "reserved":
          return "Забронирована";
        case "preparing":
          return "Подготовка";
        case "charging":
          return "Зарядка";
        case "end":
          return "Зарядка завершена";
        case "paid":
          return "Зарядка завершена, оплачена";
        case "waiting_payment":
          return "Зарядка завершена, не оплачена";
        case "interrupted":
          return "Прервана/Неоплачена";
      }
    },
    status_color(status) {
      switch (status) {
        case "end":
          return "blue";
        case "waiting_payment":
          return "red";
        case "paid":
          return "green";
      }
    },
    point_info(point_number) {
      this.$router.push({
        name: "point_info",
        params: { point_id: point_number },
      });
    },
    connector_img(type) {
      let mode = this.$vuetify.theme.dark ? "dark" : "light";
      switch (type) {
        case "type2":
          return `plug-image--${mode} type2`;
        case "chademo":
          return `plug-image--${mode} chademo`;
        case "ccs2":
          return `plug-image--${mode} ccs2`;
      }
      return "";
    },
    convert_power(power) {
      if (power > 1000) {
        return (power / 1000).toFixed(2);
      }
      return power.toFixed(2);
    },
    stop_charging() {
      this.$store
        .dispatch("stop_charging", this.$route.params.charge_id)
        .then(() => {
          this.$store.dispatch(
            "load_charging_info",
            this.$route.params.charge_id
          );
        });
    },
    payment_charge() {
      this.$store
        .dispatch("payment_charging", this.$route.params.charge_id)
        .then(() => {
          this.$store.dispatch(
            "load_charging_info",
            this.$route.params.charge_id
          );
        });
    },
    go_back() {
      if (this.prevRoute != null) {
        this.$router.push({
          name: this.prevRoute.name,
          params: this.prevRoute.params,
        });
      } else {
        this.$router.push({ name: "list_charging" });
      }
    },
  },
};
</script>

<style lang="scss">
.icon {
  width: 48px;
  height: 48px;
}
.sprite.outlet {
  width: 48px;
  height: 48px;
}
.sprite {
  transform: scale(0.5);
  transform-origin: 0 0;
  margin: 0 auto;
}
</style>
